import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

export default function UserStateComponent() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const jwt_token = useSelector((state) => state.jwt_token)

  const [lastPath, setLastPath] = useState('/')

  useEffect(() => {
    if (location.pathname !== '/login' && location.pathname !== lastPath) {
      setLastPath(location.pathname)
    }
  }, [location, setLastPath, lastPath])

  const [getMe, { loading: loadingUser, data }] = useLazyQuery(
    gql`
      query {
        me {
          id
          email
          firstname
          lastname
          role
          permissions
          advertisers {
            id
            name
          }
          productStatsEnabled
        }
      }
    `,
    { fetchPolicy: 'network-only' },
  )

  useEffect(() => {
    getMe()
  }, [jwt_token, getMe])

  useEffect(() => {
    //console.log('UserStateComponent', { data, loadingUser })
    if (loadingUser) {
      dispatch({ type: 'set', init_loading: true })
      return
    }

    dispatch({ type: 'set', user: data?.me ?? null, init_loading: false })
    if (data && data.me) {
      if (location.pathname === '/login') {
        if (
          !lastPath.startsWith('/resetpassword/') &&
          !lastPath.startsWith('/reset') &&
          !lastPath.startsWith('/join')
        ) {
          navigate(lastPath)
        }
      }
    }
    if (
      !data?.me &&
      location.pathname !== '/login' &&
      !location.pathname.startsWith('/resetpassword/') &&
      !location.pathname.startsWith('/reset') &&
      !location.pathname.startsWith('/join')
    ) {
      navigate('/login')
    }
  }, [data, dispatch, loadingUser, navigate, lastPath, location])

  return null
}
